.curtain{
    position: absolute;
    left: -100%;
    top: 0;
    background: $c-main-text;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: $transition-primary;
    &.burger-script{   
      &.active{
        left: 0;
      }
    }
}