@import "functions/function";

$font-size-text: rem(18);
$font-color: $c-main-text;
$body-background-color: $c-main-bg;
$color-placeholde: $c-main-text-light;

*, *::before, *::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::placeholder {
  color: $color-placeholde;
}

::selection {
  background-color: $c-primary;
  color: #fff;
}

.align-center {
  display: flex;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $f-heading;
  text-transform: uppercase;
}
.hidden {
  display: none !important;
}

.flex-center {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

img,
button {
  user-select: none;
  -webkit-user-drag: none;
}

.text-center {
  text-align: center;
}

img {
  max-width: 100%;
}


body {
  font-family: $f-text;
  font-size: $font-size-text;
  min-width: 320px;
  position: relative;
  line-height: 1.5;
  overflow-x: hidden;
  color: $font-color;
  background:$body-background-color;
}

body input,
body textarea {
  outline: none;
  background: $c-main-bg;
}

body input:focus:required:invalid,
body textarea:focus:required:invalid {
  border-color: red;
}

body input:required:valid,
body textarea:required:valid {
  border-color: green;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  stroke-width: 2;
}
img.icon {
  width: 16px;
  height: 16px;
}

b,
strong {
  font-weight: bold;
}

@import "media";
