$color-btn: $c-primary;
$color-text: $c-main-text;
$font-size: rem(16);
$border-radius: 0;
$padding: 16px 32px;
$font-weight: 700;
$font-family: $f-heading;
$border: none;

.btn {
  position: relative;
  z-index: 0;
  display: inline-block;
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  border: $border;
  outline: none !important;
  user-select: none;
  transition-property: color, background;
  transition-timing-function: ease;
  transition-duration: $transition-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size;
  line-height: 1.3;
  padding: 18px 32px 14px 32px;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: $border-radius;
  overflow: hidden;
  text-align: center;
  background: $color-btn;
  color: $color-text;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 550px;
    height: 550px;
    background: $c-primary;
    border-radius: 50%;
    z-index: -1;
    transform: translateX(-50%) translateY(-5%) scale(.4);
    transition: $transition-primary;
  }

  &:hover {
    text-decoration: none;
    &::after {
      transform: translateX(-45%) translateY(0) scale(1);
      //transform-origin: bottom center;
      transition: transform .9s ease;
    }
  }

  &:active {
    transform: translateY(1px);
  }


  &--clear {
    background-color: transparent;
    padding: 0;
    border: none;
    outline: none;
    color: inherit;
    border-radius: 0;
    text-decoration: none;
    cursor: pointer;
    transition-property: color, background;
    transition-timing-function: ease;
    transition-duration: $transition-primary;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;

    &::after {
      display: none;
    }

    &:hover {
      color: inherit;
    }
  }

  



  &--main {
    background-color: $c-main-text;
    color: #fff;
  }
}
