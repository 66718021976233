$transition_screen: $md;
$color_burger: $c-main-text;

.burger{
  width: 40px;
  height: 30px;
  @include flex-center();
  position: relative;
  cursor: pointer;
  display: none;
  span, &::after, &::before{
    width: 100%;
    height: 2px;
    background: $color_burger;
    transition: $transition-primary;
  }
  &::after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
  }
  &::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
  }
  &.active{
    span{
      opacity: 0;
      visibility: 0;
    }
    &::before{
      transform: rotate(45deg);
      bottom: 14px;
    }
    &::after{
      transform: rotate(-45deg);
      top: 14px;
    }
  }
  @include media($transition_screen){
    display: flex;
  }
}

.nav{
  z-index: 3;
  position: fixed;
  left: 0;
  top: 0;
  width: 260px;
  height: 100vh;
  background: #ffff;
  padding: rem(20);
  transition: $transition-primary;
  .nav__logo{
    width: 80px;
  }
  .nav__menu{
    margin-top: rem(30);
    li{
      margin-bottom: rem(14);
      font-size: 18px;
    }
  }
  &.active{
    left: 0;
  }
}

.nav__social{
  display: flex;
  align-items: center;
  margin-bottom: rem(30);
  margin-top: rem(30);
  .nav__link{
    margin-right: rem(20);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: $c-primary;
    @include flex-center();
    .icon{
      font-size: rem(20);
      color: #ffff;
      
    }
  }
}



