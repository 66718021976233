$border-color: $c-main-text-light;
$padding: 16px;
.accordion {
    width: 100%;
}
.accordion .accordion__item {
    border: 1px solid $border-color;
    // border-left: 1px solid $border-color;
    // border-right: 1px solid $border-color;
    padding: $padding;
}
// .accordion .accordion__item:last-child{
//     border-bottom: 1px solid $border-color;
// }
.accordion .accordion__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    position: relative;
    cursor: pointer;
}
.accordion p {
    margin: 0;
}
.accordion .accordion__title span {
    display: block;
    font-weight: 700;
}
.accordion .accordion__text {
    padding: $padding 0;
    display: none;
}
.accordion .accordion__title .icon {
    transition: 0.3s;
    margin-left: 20px;
}
.accordion .accordion__title.active .icon {
    transform: rotate(180deg);
}