fieldset {
  padding: 0 !important;
  border: 0 !important;
}

figure {
  margin: 0;
}

img, picture {
  line-height: 0;
  font-size: 0;
}
