@import "normalize.css/normalize";
@import "swiper/swiper-bundle";
// functions //
@import "functions/reset";
@import "functions/vars";
@import "functions/mixins";
@import "functions/fonts";
@import "functions/config";
@import "functions/function";
@import "functions/base";
@import "functions/tipography";
@import "functions/grid";

// modules //
// Пример подключения:
@import "../src/modules/nav/nav";
@import "../src/modules/button/buttons";
@import "../src/modules/curtain/curtain";
@import "../src/modules/accordion/accordion";


//blocks
@import "blocks/blocks.scss";

@import "media";

