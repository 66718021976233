@mixin small {
  @media (max-width: #{$xs}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{$sm}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$smd}) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: #{$md}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$lg}) {
    @content;
  }
}


@mixin media($media) {
  @media (max-width: $media) {
    @content;
  }
}

@mixin media-min($media-min) {
  @media (min-width: $media-min) {
    @content;
  }
}

@mixin media-height($media-height) {
  @media (max-height: $media-height) {
    @content;
  }
}

@function col($col) {
  @return 100%/12 * $col;
}

@mixin col($col) {
  max-width: col($col);
  flex: 0 0 col($col);
  padding-left: $offset_one_side;
  padding-right: $offset_one_side;
  @include tablet {
    padding-right: $offset_one_side-tablet;
    padding-left: $offset_one_side-tablet;
  }
}

@mixin shift-left-col($n) {
  $val: 100% / 12 * $n;

  padding-left: #{calc(#{$val} - #{$offset_one_side})};

  @include tablet {
    padding-left: #{calc(#{$val} - #{$offset_one_side-tablet})};
  }
}

@mixin shift-left-col-lg($n) {
  $val: 100% / 12 * $n;

  padding-left: #{calc(#{$val} - #{$offset_one_side})};

  @include tablet {
    padding-left: #{calc(#{$val} - #{$offset_one_side-tablet})};
  }
}

@mixin shift-right-col($n) {
  $val: 100% / 12 * $n;

  padding-right: #{calc(#{$val} - #{$offset_one_side})};

  @include tablet {
    padding-left: #{calc(#{$val} - #{$offset_one_side-tablet})};
  }
}

@mixin shift-left($n) {
  $val: 100% / 12 * $n;

  padding-left: #{calc(#{$val} + #{$offset_one_side})};

  @include tablet {
    padding-left: #{calc(#{$val} + #{$offset_one_side-tablet})};
  }
}

@mixin shift-right($n) {
  $val: 100% / 12 * $n;

  padding-right: #{calc(#{$val} + #{$offset_one_side})};

  @include tablet {
    padding-right: #{calc(#{$val} + #{$offset_one_side-tablet})};
  }
}


@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
