$browser-context: 16;

// grid gutter
$offset_one_side: 20px;
// if adaptive
$offset_one_side-tablet: 10px;

// fonts
$f-heading: "ActayWide", arial, sans-serif;
$f-text: "Actay", arial, sans-serif;

// colors

$additional-color: #E01C26;
$c-primary: #FBCE11;
$c-main-bg: #FAF9F5;
$c-main-text: #252422;
$c-main-text-light: #8C867D;




// transitions
$transition-primary: 0.3s;
$transition-secondary: 0.2s;


$small: 320px;
$mobile:560px;
$middle: 760px;
$tablet: 960px;
$laptop: 1160px;
$desktop: 1160px;
$wide: 1340px;

// breakpoints
$xs: 420px;
$sm: 580px;
$smd: 790px;
$md: 1000px;
$lg: 1200px;
$extra-lg: 1440px;
$xlg: 1925px;
