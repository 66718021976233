body{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}
.nav{
    background: $c-main-text;
    padding: rem(24);
    width: 250px;
   // position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 700px;
    .nav__logo{
        width: 202px;
        height: 64px;
    }
    .nav__menu{
       // margin: 95.5px 0;
       margin-top: rem(40);
        li{
            margin-bottom: rem(16);
            line-height: 21px;
            a{
                font-size: rem(15);
                letter-spacing: 2px;
                text-transform: uppercase;

            }
        }
        li.active a{
            color: $c-primary !important;
        }
    }

    .nav__contact{
       // margin: 95.5px 0;
       margin-bottom: rem(40);
        .nav__phone{
            font-size: rem(16);
            font-family: $f-heading;
            font-weight: 700;
            letter-spacing: 0.6px;
        }
        .nav__adress{
            color: $c-main-text-light;
            display: block;
            margin-bottom: rem(16);
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .nav__bottom{
        color: $c-main-text-light;
        font-size: rem(11);
    }

    // прокрутка


    &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
        background-color: #f9f9fd;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $c-main-text-light;
      }
      @include media($md){
        left: -250px;
      }
}
.nav__social{
    margin: rem(40) 0;
    .nav__link{
        width: 52px;
        height: 52px;
        background: transparent;
        border: 1px solid $c-main-text-light;
        transition: $transition-primary;
        &:last-child{
            margin-right: 0;
        }
        .icon{
            font-size: rem(24);
            color: $c-main-text-light;
            transition: $transition-primary;
        }
        &:hover{
            border: 1px solid $c-primary;
            background: $c-primary;
            .icon{
                color: $c-main-text;
            }
        }
    }
}

.firstScreen{
    @include media($sm){
        .mainBlock__content{
            .title{
                display: none;
            }
        }
    }

}
.mainBlock{
    padding-left: 250px;
   // max-height: 100vh;
    .mainBlock__slider{
       // min-height: 700px;
        height: 100%;
        .swiper-slide{
            width: 100%;
            height: 100vh;
            min-height: 720px;
            background-position: bottom;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            display: flex;
            align-items: center;
            @include media($md){
                align-items: start;
                min-height: 0;
            }
            .wrapper{
                flex: 1 1 auto;
                z-index: 2;
                @include media($sm){
                    height: 100vh;
                }
            }
            &::after{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: $c-main-text;
                opacity: 0.8;
            }
            &.secondScreen{
                &::after{
                    opacity: 0;
                }

            }
        }
        .swiper-button-next, .swiper-button-prev{
            color: $c-main-bg;
            width: 52px;
            height: 52px;
            border: 1px solid $c-main-bg;
            top: 50%;
            z-index: 110!important;
            @include flex-center();
            border-radius: 50%;
            .icon{
                color: $c-main-bg;
                font-size: 24px;
            }
            &::after{
                display: none;
            }
            &.white{
                color: $c-main-text;
                border: 1px solid $c-main-text;
                .icon{
                    color: $c-main-text;
                }
            }
            @include media(1520px){
                top: auto;
                bottom: 20px;
            }
        }
        .swiper-button-prev{
            left: 187px;
            transform: rotate(180deg);
            @include media(1855px){
                left: 20px;
            }
        }
        .swiper-button-next{
            right: 187px;
            @include media(1855px){
                right: 20px;
            }
        }

        .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
          bottom: 100px !important;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          @include media(1600px){
            bottom: 30px !important;
          }
        }
        .swiper-pagination-bullet{
            width: 8px;
            height: 8px;
            border: 0;
            margin: 0 8px;
            opacity: 1;
            border-radius: 0;
            background: #80807D99;

            &-active{
                background: $c-primary;
                width: 16px;
                height: 16px;
            }
        }
    }
    .mainBlock__content{
        flex: 1 1 100%;
        padding: rem(100) 0;
        padding-top: rem(40);
        text-align: center;
        @include media($sm){
            padding-top: 0 !important;
        }
        .title{
            color: $c-main-bg;
            font-weight: 700;
            font-size: rem(64);
            margin-top: 0;
            margin-bottom: 0;
            line-height: normal;
            line-height: 80px;

        }
        p{
            color: $c-main-bg;
            font-size: rem(36);
            font-family: $f-heading;
            font-weight: 700;
            margin-bottom: rem(40);
            margin-top: rem(18);
            letter-spacing: 4px;
            line-height: 45px;
            text-transform: uppercase;
        }
        @include media($xs){
            .title{
                font-size: rem(20) !important;
                line-height: 30px !important;
                padding-top: 20px;
            }
           p{
                font-size: rem(18) !important;
                line-height: 27px !important;
           }
        }
    }
    .mainBlock__textBlock{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .mainBlock__text{
            flex: 0 0 calc(50% - 20px);
            color: $c-main-bg;
            display: flex;
            justify-content: center;
            &:first-child{
                margin-right: rem(20);
            }
            span{
                display: inline-block;
                max-width: 360px;
                padding-left: rem(20);
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 2px;
                    height: 100%;
                    background: $c-main-bg;
                }
            }
            @include media($sm){
                flex: 0 0 100%;
                &:first-child{
                    margin-right: 0;
                    margin-bottom: rem(20);
                }
            }
        }
    }
    @include media(1250px){
        .mainBlock__slider{
            .wrapper{
                padding: 0 30px;
            }
            .swiper-button-prev{
                left: 6px;

            }
            .swiper-button-next{
                right: 6px;
            }
        }
    }
    @include media($md){
        padding-left: 0;
        .mainBlock__slider{
            .swiper-slide{
                height: calc(100vh - 76px);
            }
        }
    }

}


.btn{
    text-transform: uppercase;
    overflow: visible;
    transition: $transition-primary;
    &::after{
        background: $additional-color;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjE3MiA5LjAwMDY2TDAgOS4wMDA2NlY3LjAwMDY2TDEyLjE3MiA3LjAwMDY2TDYuODA4IDEuNjM2NjZMOC4yMjIgMC4yMjI2NTZMMTYgOC4wMDA2Nkw4LjIyMiAxNS43Nzg3TDYuODA4IDE0LjM2NDdMMTIuMTcyIDkuMDAwNjZaIiBmaWxsPSIjMjUyNDIyIi8+Cjwvc3ZnPgo=);
        background-repeat: no-repeat;
        background-position: -20px ;
        z-index: 2;
        right: -8px;
        left: auto;
        top: -8px;
        width: 20px;
        height: 20px;
        border-radius: 0;
        transform: none;
        margin: 0;
        transition: $transition-primary;
    }
    &:hover{
        padding: 16px 47px 16px 17px;
        transition: $transition-primary;
        /*background: $additional-color;*/
        &::after{
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjE3MiA5LjAwMDY2TDAgOS4wMDA2NlY3LjAwMDY2TDEyLjE3MiA3LjAwMDY2TDYuODA4IDEuNjM2NjZMOC4yMjIgMC4yMjI2NTZMMTYgOC4wMDA2Nkw4LjIyMiAxNS43Nzg3TDYuODA4IDE0LjM2NDdMMTIuMTcyIDkuMDAwNjZaIiBmaWxsPSIjMjUyNDIyIi8+Cjwvc3ZnPgo=);
            background-repeat: no-repeat;
            background-position: center;
            transform: translateX(8px) translateY(-2px);
            width: 52px;
            height: 52px;
            transition: $transition-primary;
        }

    }
}
.bg-none{
    background: rgba(0,0,0,0) !important;
}
.thirdScreen{
    .row-flex{
        justify-content: center;
    }
    .mainBlock__content{
        padding-bottom: 0;
        margin-bottom: rem(40);
      .title{
        font-size: rem(36);
        letter-spacing: 4px;
        line-height: 45px;
      }
    }
    .thirdScreen__item{
        flex: 0 0 33.333%;
        max-width: 293.33px;
        margin-right: 40px;
        &:last-child{
            margin-right: 0;
        }
        .thirdScreen__content{
           background: $c-main-bg;
           padding: rem(20);
           position: relative;
           transition: $transition-primary;
            height: 100%;

           .title{
                font-size: rem(18);
                font-family: $f-heading;
                font-weight: 700;
                line-height: 27px;
                display: block;
                margin-bottom: rem(8);
                margin-right: 20px;
                transition: $transition-primary;
                color: $c-main-text;
            }
           .text{
                font-size: rem(18);
                color: $c-main-text-light;
            }
            .icon{
                font-size: rem(24);
                min-width: 24px;
                position: absolute;
                right: 20px;
                top: 20px;
                transition: $transition-primary;
            }
            &:hover{
                .title, .icon{
                    color: $c-primary!important;
                }
            }
        }
        @include media(1230px){
            flex: 0 0 100%;
            max-width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
            a{
                width: 100%;
            }
        }
        @include media($smd){
           padding: 0 rem(20);
        }

    }
}

.fourthScreen{
    .mainBlock__content{

        .title{
            font-size: rem(36);
            font-weight: 700;
            line-height: 45px;
            letter-spacing: 4px;
        }
        .text{
            display: flex;
            justify-content: center;
        }
        p{
            font-size: rem(22);
            line-height: 33px;
            letter-spacing: 3px;
            font-family: $f-text;
            font-weight: 400;
            max-width: 760px;

        }
    }
}

.fifthScreen{
    .mainBlock__content{
        max-width: 960px;
        @include media-min(1925px){
            max-width: 1100px;
        }
        .btn{
            margin-top: rem(37);
        }
        margin-bottom: rem(100);
        @include media($sm){
            margin-bottom: rem(40);
        }
    }
    .thirdScreen__item{
        max-width: 260px;
        .thirdScreen__content{
            background: $c-main-text;
            .text{
                color: $c-main-bg;
            }
        }
        @include media(1000px){
            margin-bottom: 100px;
        }
    }
    .thirdScreen__item{
        @include media(1235px){
           margin-right: rem(40);
        }
        @include media(1130px){
            // margin-right: 0;
             max-width: 226px
            // margin-bottom: 0;
         }
         @include media(750px){
             margin-right: 0;
             max-width: 100%;
             margin-bottom: 10px;
         }
    }
}

.sixthdScreen{
    .mainBlock__content{
        margin-bottom: rem(40);
        .text{
            display: flex;
            justify-content: center;
        }
        p{
            font-family: $f-text;
            font-size: rem(22);
            line-height: 33px;
            letter-spacing: 3px;
            max-width: 760px;
            margin-bottom: 0;
        }
    }
    .sixthdScreen__item{
        //flex: 0 0 25%;
        max-width: 33%;
        margin: 0;
        .thirdScreen__content{
            padding: 0;
            position: relative;
            img{
                width: 100%;
                height: 230px;
                display: flex;
                object-fit: cover;
                @include media($sm){
                    height: 150px;
                }

            }
            .sixthdScreen__text{
                position: relative;
                margin-left: auto;
                margin-right: auto;
                margin-top: -36px;
                padding: rem(20);
                background: $c-main-text;
                width: 84%;
                .title{
                    color: $c-main-bg;
                }
                .title.white{
                    color: $c-main-bg !important;
                }
                .icon{
                    right: 5px;
                    top: 20px;
                }
                @include media(1120px){
                    .title{
                        font-size: rem(16);
                        line-height: 20px;
                    }
                }
                @include media(1055px){
                    .title{
                        margin-right: 0;
                    }
                    .icon{
                        display: none;
                    }
                }
            }
        }
    }
}

.mainForm{
    background: $c-main-bg;
    padding: rem(40);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 460px;
    .title{
        text-align: center;
    }
    .mainForm__item{
        width: 100%;
        margin-bottom: rem(16);
        .text{
            display: block;
            margin-bottom: 4px;
        }
        input{
            width: 100%;
            padding: 12px;
            border: 1px solid $c-main-text-light;
        }
    }
    .mainForm__politic{
        span{
            display: block;
            padding-left: 34px;
            a{
                display: inline;
                color: $c-main-text;
                text-decoration: underline;
                &:hover{
                    color: $c-primary;
                }
            }
        }
        label{
            position: relative;
            .fake-checkbox{
                position: absolute;
                left: 0;
                top: 0;
                background: transparent;
                width: 24px;
                height: 24px;
                border: 1px solid $c-primary;
                transition: $transition-primary;
                @include flex-center();
                .icon{
                    opacity: 0;
                    visibility: 0;
                    transition: $transition-primary;
                }
            }
            input{
                display: none;
                &:checked + .fake-checkbox{
                    background: $c-primary;
                    .icon{
                        opacity: 1;
                        visibility: 1;
                    }
                }
            }
        }

    }
    .btn{
        margin-top: rem(40);
    }
}
.seventhdScreen{
    .mainForm{
        margin: rem(40) rem(20);
        @include media($sm){
            margin-top: 10px!important;
        }
    }

    @include media(420px){
        .mainForm{
            .title{
                font-size: rem(20);
                line-height: 30px;
            }
        }
    }
}

.secondScreen{
    .mainBlock__content{
        .title{
            color: $c-main-text
        }
        .text{
            p{
                color: $c-main-text;
            }
        }
        @include media(430px){
            margin-top: 0;
            margin-bottom: 0;
            .title{
                font-size: rem(20);
                line-height: 30px;
            }
            .text{
                p{
                    font-size: rem(18);
                    line-height: 27px;
                }
            }
        }
    }
    .sixthdScreen__item{
      max-width: 33.333%;
      flex: 0 0 33.333%;
      display: flex;
      justify-content: center;
      .thirdScreen__content{
          display: flex;
          justify-content: start;
          align-items: center;
          flex-direction: column;
        max-width: 260px;
          &-img{
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              max-height: 180px;
          }
      }
    }
}

.header{
    padding: 12px 0;
    background: $c-main-text;
    position: relative;
    z-index: 2;
    display: none;
    .header__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .nav__social{
        margin: 0;
    }
    a{
        display: flex;
        font-size: 15px;
        color: $c-main-bg;
        .header__logo{
            width: 165px;
            max-height: 52px;
            margin: 0 rem(20);
        }
    }

    .burger-block{
        width: 52px;
        height: 52px;
        background: $c-primary;
        @include flex-center();
        border-radius: 50%;
        .burger{
            width: 21px;
            height: 12px;
            &.active{
                &::after{
                    top: 5px;
                }
                &::before{
                    bottom: 5px;
                }
            }
        }
    }
    @include media($md){
        display: block;
    }
    @include media(650px){
        .nav__link:first-child{
            display: none;
        }
    }
    @include media(550px){
        .nav__link:last-child{
            display: none;
        }
    }
    @include media(500px){
        .nav__social{
            display: none;
        }
        a .header__logo{
            width: 75px;
            max-height: 24px;
            margin: 0 rem(10);
        }
    }
}
.thirdScreen__item.sixthdScreen__item>.thirdScreen__content{
    cursor: default;
}
.sixthdScreen__content{
    .sixthdScreen__item{
        &:last-child{
           // display: none;
        }
    }
    .thirdScreen__content{
        background: rgba(0,0,0,0) !important;
    }
    @include media(1380px){
        .sixthdScreen__item{
            .icon{
                display: none;
            }

            .title{
                margin-right: 0 !important;
                font-size: 16px !important;
                word-wrap: break-word;
            }
        }
        .sixthdScreen__text{
            padding: rem(10) !important;
        }
    }
    @include media(1140px){

        justify-content: center;

        .sixthdScreen__item{
            max-width: 50%;
            &:last-child{
                display: none;
            }
        }
    }
    @include media(650px){


        .sixthdScreen__item{
            max-width: 100%;
            &:first-child{
                display: none;
            }
        }
    }
}


.title-block{
    @include flex-center();
    .title{
        font-size: rem(28);
        line-height: 35px;
        letter-spacing: 3px;
        margin: 0;
    }
    margin-bottom: rem(40);
}

.about{
    &__col-3{
        max-width: 33%;
        flex: 0 0 33%;
        padding-left: 20px;
        padding-right: 20px;
        @include media(1000px){
            max-width: 50%;
            flex: 0 0 50%;
        }
        @include media(500px){
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

   .about__imgBlock{
        img{
            display: flex;
            width: 100%;
            object-fit: cover;
            object-position: center;
            max-height: 312px;
            height: 100%;
        }
   }

   @include media(700px){
    .col-6{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .about__content{
        margin-bottom: rem(20);
    }
   }
   @include media(500px){
    padding-bottom: 88px;
   }
}

.principles{
    position: relative;
    .principles__bg{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        max-height: 600px;
        max-width: 960px;
        z-index: -1;
        opacity: 0.5;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        border: 1px solid $c-main-text-light;

    }
    .principles__item{
        margin-bottom: rem(40);
        .principles__content{
            background: $c-main-text;
            padding: rem(20);
            position: relative;
            width: 100%;
            height: 100%;
            .title, .text{
                color: $c-main-bg;
                font-size: rem(18);
                line-height: 27px;
            }
            .title{
                font-family: $f-heading;
                font-weight: 700;
                display: block;
                margin-bottom: rem(20);
            }
            &::after{
                content: '';
                position: absolute;
                left: -8px;
                bottom: -8px;
                width: 16px;
                height: 16px;
                background: $additional-color;
            }
        }

    }
    @include media(2000px){
        .principles__bg{
            margin-left: 125px;
        }
    }
    @include media($lg){
        .principles__bg{
            padding-right: 20px;
            margin-right: auto;
            margin-left: auto;
            max-width: 640px;
            margin-left: 125px;
        }
    }
    @include media($md){
        .principles__bg{
            padding-right: 20px;
            margin-right: auto;
            margin-left: auto;
            max-width: 640px;
            margin-left: 0;
        }
    }
    @include media(660px){
        .principles__bg{
            padding-right: 20px;
            margin-right: auto;
            margin-left: auto;
            max-width: 100%;
            margin-left: 0;
            max-height: 100%;
        }
    }
    @include media($sm){
        .principles__item{
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}


.history{
    .history__item{
        display: flex;
        align-items: end;
        margin-bottom: rem(40);

        img{
            width: 100%;
            height: 100%;
            max-width: 200px;
            height: 160px;
            object-fit: cover;
            object-position: center;
        }
        .history__content{
            padding: rem(20);
            background: $c-main-text;
            .title, .text{
                color: $c-main-bg;
            }
            .title{
                font-size: rem(16);
                line-height: 20px;
                letter-spacing: 3px;
                font-family: $f-heading;
                font-weight: 700;
                display: block;
                margin-bottom: rem(8);
            }
            .text{
                font-size: rem(18);
                line-height: 27px;
            }
            position: relative;
            &::after{
                content: '';
                position: absolute;
                right: -20px;
                top: 50%;
                height: 1px;
                width: 20px;
                background: $c-main-text-light;
            }
            &::before{
                content: '';
                position: absolute;
                width: 6px;
                height: 6px;
                right: -23px;
                top: 50%;
                background: $c-main-text-light;
                transform: translate(0px, -3px) rotate(45deg);
            }
        }
    }
    .history__block--reverse{
        margin-top: rem(49);
        position: relative;
        &::after{
            content: '';
            position: absolute;
            left: 0;
            top: calc(0% - 49px);
            width: 1px;
            height: calc(100% + 10px);
            background: $c-main-text-light;
        }
        .history__item{
            flex-direction: row-reverse;
            .history__content{
                &::after{
                    content: '';
                    position: absolute;
                    right: 0px;
                    left: -20px;
                    top: 50%;
                    height: 1px;
                    width: 20px;
                    background: $c-main-text-light;
                }
                &::before{
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    right: 0;
                    left: -23px;
                    top: 50%;
                    background: $c-main-text-light;
                    transform: translate(0px, -3px) rotate(45deg);
                }
            }
        }
    }
    @include media(1170px){
        .history__block{
            flex: 0 0 100%;
            max-width: 100%;
            overflow-y: visible;
            overflow-x: clip;
            .history__item{
                max-width: 540px;
                .history__content{
                    &::after{

                        right: auto;
                        left: calc(100% + 23px);

                        width: 100%;

                    }
                    &::before{

                        right: -23px;
                        left: auto;

                    }
                }
            }
        }
        .history__block--reverse{
            overflow-y: visible;
            overflow-x: clip;
            margin-top: 0;
            .history__item{
                flex-direction: row;
                .history__content{
                    &::after{

                        right: auto;
                        left: calc(100% + 23px);

                        width: 100%;

                    }
                    &::before{

                        right: -23px;
                        left: auto;

                    }
                }
            }
            &::after{
                content: '';
                position: absolute;
                left: auto;
                right: 0;
                top: calc(0% - 105%);
                width: 1px;
                height: 200%;
                background: $c-main-text-light;
            }
        }
    }
    @include media(450px){
        .history__block .history__item{
            flex-direction: column;
            align-items: start;
            max-width: 320px;
            img{
                max-width: 100%;
            }

        }


    }
    @include media(400px){
        .history__block .history__item{
            max-width: 100%;
        }
        .history__block--reverse{
            &::after{
                display: none;
            }
        }
    }
}
.sldierGallery{
    width: 100%;
    max-width: 960px;
    max-height: 580px;
    margin-bottom: 20px;
    .partners__item{
        padding-bottom: 0;
    }
    .swiper-button-next, .swiper-button-prev{
        top: 60%;
    }
    @include media(1550px){
        padding-bottom: rem(120);
        .swiper-button-next, .swiper-button-prev{
            top: auto;
            bottom: -100px;
        }
    }
    .partners__content:hover{
        .loop{
            opacity: 1;
            visibility: visible;
        }
    }
    .loop{
        position: absolute;
        left: 43%;
        top: 45%;
        transform: none;
        opacity: 0;
        visibility: hidden;
        .icon{
            color: $c-main-text-light;
        }
    }
    .loop:hover{
        border: none;
        .icon{
            color: $c-main-text;
        }

    }
    img{
        max-height: 580px;
        width: 100%;
        height: 100%;
        display: flex;
        object-fit: cover;
        object-position: center;
    }
}
.sldierGallery-thumb{
    width: 100%;
    max-width: 960px;
    .swiper-slide{
        filter: grayscale(100%);
    }
    .swiper-slide-thumb-active{
        filter: grayscale(0%);
    }
}
.partners__content{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    &-img{
        width: 100%;
    }
}
.partners{
    .col-3{
        .partners__text{
            .title{
                word-wrap: break-word !important;
            }
        }
        @include media(1400px){
            .partners__text{
                padding: 14px !important;
            }
        }
        @include media(1340px){
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: rem(20);
        }
        @include media(500px){
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: rem(20);
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .partners__item{
        height: auto;
        .partners__content{
            position: relative;
            height: 100%;
            a{
                display: contents !important;
            }
            img{
                display: flex;
                width: 100%;
                height: 100%;
                min-height: 180px;
                max-height: 180px;
                object-fit: cover;
            }
            .partners__text{
                position: relative;
                min-height: 102px;
                margin-top: -36px;
                width: 89%;
                background: $c-main-text;
                padding: rem(20);
                height: 100%;
                .title{
                    color: $c-main-bg;
                    display: block;
                    margin-right: 20px;
                    font-family: $f-heading;
                    font-size: rem(18);
                    font-weight: 700;
                    line-height: 27px;
                    margin-bottom: rem(8);
                    transition: $transition-primary;
                }
                .text{
                    color: $c-main-text-light;
                    font-size: rem(18);
                    line-height: 27px;
                }
                .icon{
                    position: absolute;
                    transition: $transition-primary;
                    right: 10px;
                    top: 17px;
                    font-size: rem(30);
                }
            }
            &:hover .title{
                color: $c-primary !important;
            }
            &:hover .title.white{
                color: $c-main-bg !important;
            }
        }
    }
    @include media($lg){
        .partners__text{
            .title{
                font-size: rem(16) !important;
                margin-right: rem(14) !important;
            }
            .icon{
                font-size: rem(24) !important;
            }
            .text{
                font-size: rem(16) !important;

            }
        }
    }
    @include media(620px){
        .col-4{
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: rem(20);
            &:last-child{
                padding-bottom: 24px;
            }
        }
    }

    .careers-pagination{
        display: none;
        bottom: 0!important;
        @include media(1550px){
            padding-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .swiper-pagination-bullet{
            margin: 0 8px !important;
        }
    }
    &__slider{
        .swiper-slide{
            height: auto !important;
            .partners__item{
                height: 100%;
            }
        }
    }
}

.purpose{
    display: flex;
    justify-content: center;
    .purpose__content{
        display: flex;
        align-items: center;
        max-width: 960px;
        .title{
            font-size: rem(28);
            line-height: 35px;
            letter-spacing: 3px;
            display: block;
            max-width: 460px;
            font-weight: 700;
        }
        ul{
            padding-left: 156px;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                left: 78px;
                top: calc(0% + 13px);
                width: 1px;
                height: calc(100% - 48px);
                background: $c-main-text-light;
            }
            &::before{
                content: '';
                position: absolute;
                left: 8px;
                top: calc(50% - 20px);
                height: 1px;
                width: 70px;
                background: $c-main-text-light;
            }
            li{
                position: relative;
                margin-bottom: rem(20);
                &::after{
                    content: '';
                    position: absolute;
                    left: -78px;
                    top: 50%;
                    height: 1px;
                    width: 70px;
                    transform: translate(0, -2px);
                    background: $c-main-text-light;
                }
                &::before{
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    left: -11px;
                    top: 50%;

                    background: $c-main-text-light;
                    transform: translate(0px, -4px) rotate(45deg);
                }
            }
        }
    }
    @include media(700px){
        .purpose__content{
            flex-direction: column;
            .title{
                max-width: 100%;
                font-size: rem(18);
                line-height: 22px;
            }
            ul{
                &::after, &::before{
                    display: none;
                }
                padding-left: 0;
                li{
                    padding-left: 18px;
                    &::after{
                        display: none;
                    }
                    &::before{
                        width: 8px;
                        height: 8px;
                        background: $c-primary;
                        left: 4px;
                        top: 10px;
                    }
                }
            }
        }
    }
}

.feedback{
    display: flex;
    justify-content: center;
    .feedback__contet{
        width: 100%;
        max-width: 960px;
        display: flex;
        align-items: center;
    }
    .feedback__img{
        width: 100%;
        height: 100%;
        min-height: 685px;
        min-width: 500px;
        display: flex;
        object-fit: cover;
        object-position: center;
    }
    .mainForm{
        min-width: 460px;
        border: 1px solid $c-main-text-light;
    }
    &__modal{
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 999999;
        overflow: auto;
        transition: transform .3s ease;
        display: none;
        align-items: center;
        justify-content: center;
        transform: scale(1);
        width: 100vw;
        height: 100vh;
        &.active{
            display: flex;
        }
        &-bg{
            position: absolute;
            z-index: 1000;
            width: 100vw;
            height: 100vh;
            background: $c-main-text;
            opacity: 50%;
        }
        &-wrap{
            position: relative;
            display: flex;
            justify-content: center;
            margin: 0 15px;
        }
        &-img{
            width: 360px;
            img{
                position: absolute;
                top: -14%;
                right: 0;
                z-index: 1111;
                max-width: 560px;
                max-height: 400px;
            }
        }
        &-block{
            position: relative;
            z-index: 10000;
            background: $c-main-text;
            padding: 40px;
            max-width: 560px;
            border: 1px solid $c-main-text-light;
            h2{
                color: $c-main-bg;
                font-size: 28px;
            }
            p{
                color: $c-main-text-light;
                margin-bottom: 40px;
            }
        }
        &-close{
            position: absolute;
            top: 16px;
            right: 16px;
            button{
                border: none;
                background: $c-main-text;
                &:hover{
                    cursor: pointer;
                    svg{
                        color: $c-primary;
                    }
                }
            }
            svg{
                    color: $c-main-bg;
                }
        }
    }
    @include media(1240px){
        .mainForm{
            min-width: auto;
        }
        .feedback__img{
            min-height: 585px;
            min-width: 400px;
        }
    }
     @include media(700px){
         &__modal-block{
             margin: 0 20px;
             h2{
                 font-size: 18px;
             }
             p{
                 font-size: 15px;
                 margin-bottom: 20px;
             }

         }
        &__modal-wrap{
            flex-direction: column;
        }
         &__modal-img{
             margin-top: -40px;
            width: 100%;
             img{
                 position: relative;
                 width: 100%;
                 max-width: 100%;
                 max-height: 200px;
             }
         }
        .feedback__contet{
            flex-direction: column-reverse;
        }
     }
     @include media(420px){
        .feedback__img{
            min-height: auto;
            min-width: 100%;
        }
     }
}

.footer{
    background: $c-main-text;
    .footer__content{
        padding: rem(20) 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span, a{
            color: $c-main-text-light;
        }
        a{
            margin-right: rem(20);
            &:hover{
                color: $c-primary;
            }
        }
    }
    @include media(500px){
        .footer__content{
            flex-direction: column;
            align-items: start;
            a{
                margin-right: 0;
                margin-bottom: rem(20);
            }
        }
    }
}

.section-spacer, .footer{
    @include media(2000px){
        padding-left: 250px;
    }
    @include media($md){
        padding-left: 0;
    }
}

.products{
    .products__item{
        &:last-child:nth-child(2n){
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 0;
        }
        .partners__content{
            a{
                display: contents !important;
                width: 100%;
            }
            img{
                height: 100%;
                width: 100%;
                display: flex;
                object-fit: cover;
                min-height: auto;
                max-height: 360px;
            }
        }
    }
    @include media($smd){
        .products__item{
           margin-bottom: rem(20);
        }
    }
    @include media(500px){
        .products__item, .products__item:last-child{
           max-width: 100% !important;
           flex: 0 0 100% !important;
         }
    }
}






.material{
    .title{
        margin-top: 0;
        margin-bottom: rem(40);
        font-size: rem(28);
        line-height: 35px;
    }
    .btn{
        margin-top: rem(40);
    }
    .sliderMaterial{
        max-height: 360px;
        margin-bottom: rem(20);
        .swiper-wrapper{
            max-height: 360px;
            .swiper-slide{
                max-height: 360px;
            }
        }
    }
    .sliderMaterial-thumb{
        img{
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 160px;
            max-height: 160px;
            object-fit: cover;
        }
    }
    .loop{
        position: absolute;
        left: 50%;
        bottom: 40%;
        @include flex-center();
        width: 52px;
        height: 52px;
        background: $c-main-bg;
        border-radius: 50%;
        z-index: 3333;
        transform: translate(-50%, 0);
        color: $c-main-text;
    }
    .swiper-button-next, .swiper-button-prev{
        color: $c-main-text;
        width: 52px;
        height: 52px;
        top: auto;
        bottom: 40%;
        background: $c-main-bg;
        @include flex-center();
        border-radius: 50%;
        transition: $transition-primary;
        .icon{
            color: $c-main-text;
            font-size: 24px;
        }
        &::after{
            display: none;
        }
        &.swiper-button-disabled{
            opacity: 1;
            .icon{
                color: $c-main-text-light;

            }
        }
        &:hover{
            background-color: $c-primary;
        }
    }
    .swiper-button-prev{
        left: 16px;
        transform: rotate(180deg);

    }
    .swiper-button-next{
        right: 16px;

    }
    .material__params{
        display: flex;
        align-items: center;
        .material__tab{
           // width: 110px;
           // height: 51px;
            padding: 12px;
            @include flex-center();
            border: 1px solid $c-main-text;
            margin-right: rem(20);
            cursor: pointer;
            &.active{
                background: $c-main-text;
                color: $c-main-bg;
            }
        }
    }
    .material__info{
        margin-top: rem(20);
        p{
            color: $c-main-text-light;
        }
        span, a{
            color: $c-main-text;
        }
        a{
            // text-decoration: underline;
            display: inline-flex;
            align-items: center;
            position: relative;
            .icon{
                font-size: rem(24);
                margin-left: rem(8);
                transition: $transition-primary;
            }
            &::after{
                content: '';
                position: absolute;
                left: 0;
                bottom: 2px;
                width: 100%;
                height: 1px;
                background: $c-main-text;
                transition: $transition-primary;
            }
            &:hover{
                color: $c-primary;
                .icon{
                    color: $c-primary;
                }
                &::after{
                    background: $c-primary;
                }
            }
        }

    }
    .list{
        padding: rem(20);
        border: 1px solid $c-main-text-light;
        margin-top: rem(40);
    }
    @include media($lg){
        .sliderMaterial{
            min-height: 300px;
            img{
                min-height: 300px;
            }
        }
    }
    @include media(700px){
        .col-6{
            flex: 0 0 100%;
            max-width: 100%;
            &:first-child{
                margin-bottom: rem(40);
            }
        }
        .sliderMaterial-thumb{
            img{
                width: 100%;
            }
        }
    }
    @include media(370px){
        .material__tab{
            span{
                font-size: rem(16);
            }
            &:last-child{
                margin-right: 0;
            }
        }

    }
    @include media(450px){
        .btn{
            width: 100%;
        }
    }
}

.list{

    li{
        padding-left: 24px;
        position: relative;
        margin-bottom: rem(8);
        &::after{
            content: '';
            position: absolute;
            left: 0px;
            top: 0;
            width: 8px;
            height: 8px;
            background: $c-primary;
            transform: translate(0, 8px) rotate(45deg);
        }
    }

}

.application{

    .title-block{
        flex-direction: column;
        text-align: left;
        align-items: start;
        .title{
            font-size: rem(22);
            line-height: 33px;
            letter-spacing: 3px;
            font-family: $f-text;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: rem(20);
        }
    }
}

.delivery{
    background: $c-main-text;
    max-width: 1280px;
    margin: 0 auto;
    padding: 60px 0;
    img{
        object-fit: cover;
    }
    .title-block{
        margin-bottom: 0;
        .title{
            color: $c-main-bg;
        }
    }
    .delivery_text{
        span{
            color: $c-main-text-light;
            display: block;
            margin-bottom: rem(20);
            &.white{
                color: $c-main-bg;
            }
        }
        .delivery__list{
            color: $c-main-bg;
        }
    }
    @include media(1000px){
        padding-left: 0;
        margin-left: 0;
        width: 100%;
    }
    @include media(600px){
        .col-6{
            flex: 0 0 100%;
            max-width: 100%;
            &:nth-child(1){
                order: 1;
            }
            &:nth-child(2){
                margin-top: 40px;
                order: 3;
            }
            &:nth-child(3){
                order: 2;
            }
            &:nth-child(4){
                order: 4;
            }
        }
    }
}
.loop{
    position: absolute;
    left: 50%;
    @include flex-center();
    width: 52px;
    height: 52px;
    background: $c-main-bg;
    border-radius: 50%;
    z-index: 3333;
    transform: translate(-50%, 0);
    transition: $transition-primary;
    cursor: pointer;
    &:hover{
        background: $c-primary;
    }
}
.document{
    .title-block{
        .title{
            margin-bottom: 0;
        }
    }
    .document__content{
        @include flex-center();
        position: relative;

        img{
            display: flex;
            max-width: 160px;
            margin-bottom: rem(20);
            object-fit: cover;
        }
        .loop{
            opacity: 0;
            visibility: hidden;
            transition: $transition-primary;
            .icon{
                color: $c-main-text-light;
            }
        }
    }
    .document__item{
        text-align: center;
        span{
            color: $c-main-text-light;
        }
        &:hover{
            .document__content{
                .loop{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .document__content{
            .loop:hover{
                border: none;
                .icon{
                    color: $c-main-text;
                }

            }
        }
    }
    @include media(700px){
        padding-bottom: rem(20);
        .col-3{
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: rem(40);
        }
    }
    @include media(450px){
        .document__content{
            img{
                max-width: 100%;
            }
        }

    }
}

.advantege{
    padding-top: rem(20);
    position: relative;
    .bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-position: bottom !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        z-index: -1;
        display: none;
    }
    .col-12{
        display: flex;
        justify-content: center;
        position: relative;
    }
    .advantege__img{
        width: 100%;
        max-width: 960px;
        max-height: 400px;
        object-fit: cover;
        object-position: bottom;

    }
    .advantege__container{
        display: flex;
        position: relative;
        left: 0;
        top: 100px;
        width: 100%;
        padding-left: 40px;
    }
    .advantege__item{
        padding: rem(20);
        background: $c-main-text;
        //max-width: calc(33.333% - 40px);
       // flex: 0 0 calc(33.333% - 40px);
       position: relative;
       z-index: 2;
       top: 40px;
        text-align: center;
        //margin-right: rem(40);
        &:last-child{
            margin-right: 0;
        }
        span{
            color: $c-main-bg;
            font-size: rem(18);
            line-height: 27px;
            font-family: $f-heading;
            font-weight: 700;
        }
        &::after{
            content: '';
            position: absolute;
            left: -8px;
            bottom: -8px;
            width: 16px;
            height: 16px;
            background: $additional-color;
        }
    }
    @include media(650px){
        background-size: contain;
        .col-12{
            display: none;
        }
        .col-4{
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: rem(40);
        }
        .advantege__item{
            top: 0;
        }
        .bg{
            display: block;
        }
    }
}

.other, .careers{
    .col-12{
        position: relative;
    }
    .swiper-button-next, .swiper-button-prev{
        color: $c-main-text;
        width: 52px;
        height: 52px;
        top: auto;
        top: 50%;
        background: $c-main-bg;
        border: 1px solid $c-main-text-light;

        @include flex-center();
        border-radius: 50%;
        transition: $transition-primary;
        .icon{
            color: $c-main-text-light;
            font-size: 24px;
        }
        &::after{
            display: none;
        }
        &.swiper-button-disabled{
            opacity: 0;
            .icon{
                color: $c-main-text-light;

            }
        }
        &:hover{
            background-color: $c-primary;
            border: 1px solid $c-primary;
            .icon{
                color: $c-main-text;
            }
        }
    }
    .swiper-button-prev{
        left: -68px;
        transform: translate(0, -50%) rotate(180deg);

    }
    .swiper-button-next{
        right: -68px;

        transform: translate(0, -50%);

    }
    @include media(1550px){
        .swiper-button-next, .swiper-button-prev{
            display: none;
            top: auto;
            bottom: -110px;
            border: 1px solid $c-main-text;
            .icon{
                color: $c-main-text;
            }
            &.swiper-button-disabled{
                opacity: 1;
                background: $c-main-bg;
                border: 1px solid $c-main-text-light;
                .icon{
                    color: $c-main-text-light;

                }
            }
        }
        .swiper-button-prev{
            right: 52%;
            left: auto;
            transform: translate(0, -50%) rotate(180deg);
        }
        .swiper-button-next{
            left: 52%;
            right: auto;
            transform: translate(0, -50%);
        }
    }
    @include media($sm){
        .swiper-button-next, .swiper-button-prev{
            bottom: -70px;
        }
        padding-bottom: rem(80);
    }
}

.careers{
    .thirdScreen__item{
        flex: none;
        max-width: 100%;
        margin-right: 0;
        padding: 0;
        border: 1px solid $c-main-text-light;
        height: auto;
        a{
            width: 100%;
            height: 100%;
        }
        .thirdScreen__content{
            height: 100%;
            .title{
                color: $c-main-text;
            }
            .text{
                span{
                    display: block;
                    margin-bottom: 8px;

                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    @include media(1550px){
        padding-bottom: rem(100);
    }
    @include media(1230px){
        .swiper-button-next, .swiper-button-prev{
            bottom: -70px;
        }
    }
}
.title-block{
    text-align: center;
    @include media(700px){

            .title{
                font-size: rem(18);
                line-height: 22px;
            }

    }
}
.paragraph{
    .title-block{
        text-align: center;
    }
    .paragraph__content{
        a{
            display: flex;
            align-items: center;
            position: relative;
            margin-bottom: rem(40);

        }
        &:hover{
            .paragraph__info{
                background: $c-main-text;
            }
            .title, .icon{
                color: $c-primary;
            }
        }
    }
    .paragraph__imgBlock{
        min-width: 76%;
        height: 400px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .paragraph__info{
        background: $c-main-bg;
        padding: rem(20);
        min-width: 360px;
        position: relative;
        transition: $transition-primary;
        border: 1px solid $c-main-text;
        left: -100px;
        .title{
            font-size: rem(18);
            font-family: $f-heading;
            font-weight: 700;
            line-height: 27px;
            display: block;
            margin-bottom: rem(8);
            margin-right: 20px;
            color: $c-main-text;
            transition: $transition-primary;
        }
        span{
            display: block;
        }
        .text{
            font-size: rem(18);
            color: $c-main-text-light;
            margin-bottom: rem(8);
            &:last-child{
                margin-bottom: 0;
            }
        }
        .icon{
            font-size: rem(24);
            min-width: 24px;
            position: absolute;
            color: $c-main-text;
            right: 20px;
            top: 20px;
            transition: $transition-primary;
        }

    }
    @include media(1340px){
        .paragraph__imgBlock{
            min-width: 71%;
        }
        .paragraph__info{
            left: -100px;
        }
    }
    @include media(1160px){
        .paragraph__imgBlock{
            min-width: 66%;
        }
        .paragraph__info{
            left: -115px;
        }
    }
    @include media(710px){
        .title-block{
            .title{
                font-size: rem(18);
                line-height: 22px;
            }
        }
         .paragraph__content{
            flex-direction: column;
            margin-bottom: -20px;
         }
         .paragraph__imgBlock{
            min-width: 100%;
           // height: auto;
            max-height: 320px;
        }
        .paragraph__info{
            left: 0;
            width: 94%;
            top: -60px;
            min-width: auto;
        }
    }
}

.careersProducts{
    padding-top: 0;
    padding-bottom: rem(100);
    .title-block{
        margin-bottom: 0;
    }
}

.careersAdvantages{
    position: relative;
    .bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-position: bottom !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        z-index: -1;
        display: none;
    }
    .careersAdvantages__imgBlock{
        min-width: 77%;
        height: 469px;
        z-index: -1;
        position: relative;
        left: -100px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .careersAdvantages__content{
        display: flex;
        align-items: center;
    }
    .careersAdvantages__info{

        .careersAdvantages__item{
            padding: rem(20);
            background: $c-main-text;
            position: relative;
            text-align: center;
            min-width: 360px;
            margin-bottom: rem(40);
            &:last-child{
                margin-bottom: 0;
            }
            span{
                color: $c-main-bg;
                font-size: rem(18);
                line-height: 27px;
                font-family: $f-heading;
                font-weight: 700;
            }
            &::after{
                content: '';
                position: absolute;
                left: -8px;
                bottom: -8px;
                width: 16px;
                height: 16px;
                background: $additional-color;
            }
        }
    }
    @include media(1410px){
        .careersAdvantages__imgBlock{
            min-width: 71%;
        }
    }
    @include media(1160px){
        .careersAdvantages__imgBlock{
            min-width: 66%;
        }
    }
    @include media(1160px){
        .careersAdvantages__imgBlock{
            min-width: 66%;
        }
    }
    @include media(790px){
        .careersAdvantages__imgBlock{
            min-width: 62%;
            left: -110px;
        }
    }
    @include media(700px){
        .careersAdvantages__imgBlock{
           display: none;

        }
        .careersAdvantages__info{
            width: 100%;
            .careersAdvantages__item{
                min-width: auto;
                width: 100%;
                left: auto;
            }
        }
        .bg{
            display: block;
        }
    }
}
.swiper-pagination.relative{
    position: relative!important;
}
.pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    .link{
        color: $c-main-text-light;
        margin-right: rem(16);
        @include flex-center();
        &.arrow{
            margin-right: rem(20);
        }
        &.active{
            font-family: $f-heading;
            font-weight: 700;
            color: $c-main-text;
        }
        &:last-child{
            margin-right: 0;
            margin-left: rem(4);
        }
        &:first-child{
            transform: rotate(180deg);
        }
    }
}
.projects{
    .products__item{
        margin-bottom: 40px;
        &:last-child{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    .loop{
        top: 40%;
        display: none;
        .icon{
            color: $c-main-text-light;
        }
    }
    .partners__item .partners__content:hover .loop{
        display: flex;
    }
    .loop:hover{
        border: none;
        .icon{
            color: $c-main-text;
        }

    }
    img{
        height: 360px !important;
    }

}
.contacts{
    .contacts__map{
        max-width: 60%;
        min-width: 60%;
        border: 1px solid $c-main-text-light;
    }
    .contacts__content{
        display: flex;
        align-items: center;
    }
    .contacts__info{
        padding: rem(40);
        background: $c-main-text;
    }
    .contacts__block{
        margin-bottom: rem(20);
        a{
            &:hover{
                span{
                    color: $c-primary;
                }

            }
        }
        &:last-child{
            margin-bottom: 0;
            .text{
                margin-bottom: 0;
            }
        }
        .title{
            color: $c-main-bg;
            font-family: $f-heading;
            font-weight: 700;
            display: block;
            margin-bottom: rem(16);
            line-height: 27px;
        }
        .text{
            display: flex;
            align-items: center;
            margin-bottom: rem(8);
            line-height: 27px;
            span{
                color: $c-main-bg;
            }
            .icon{
                font-size: rem(24);
                color: $c-main-text-light;
                margin-right: rem(8);
            }
        }
    }
    .contacts__row{
        display: flex;
        .icon{
            font-size: rem(24);
            color: $c-main-text-light;
            margin-right: rem(8);
            min-width: 24px;
        }
        div{
            a{
                display: block;
            }
        }
    }
    @include media(1110px){
        .contacts__map{
            max-width: 60%;
            min-width: 60%;
        }
        .contacts__info{
            padding: rem(20);
        }
    }
    @include media(700px){
        .contacts__content{
            flex-direction: column;
        }
        .contacts__info{
            width: 100%;
        }
        .contacts__map{
            max-width: 100%;
            min-width: 100%;
            iframe{
               max-height: 500px;
            }

        }
    }
}

.news{
    .partners__item{
        margin-bottom: 40px;
    }
    .partners__item .partners__content:hover .icon{
        color: $c-primary !important;
    }
    .partners__text{
        display: flex;
        flex-direction: column-reverse;
    }
    .partners__item .partners__content .partners__text .title{
        margin-bottom: 0;
        margin-top: rem(8);
    }
    .partners__item .partners__content .partners__text .text{
        color: $c-primary;
    }
    .partners__item .partners__content .partners__text .icon{
        color: $c-main-bg;
        top: auto;
        bottom: 17px;
    }
    .newsSlider{
        .partners__content{
            a{
                display: contents !important;
                img{
                    min-height: 250px!important;
                    max-height: 250px!important;
                }
            }

        }

    }
}

.newsInner{
    .newsInner__imgBlock{
        img{
            min-height: 400px;
            object-fit: cover;
            width: 100%;
            display: flex;
        }
    }
    .newsInner__content{
        .date{
            color: $c-main-text-light;
        }
        .title{
            font-size: rem(28);
            line-height: 35px;
            letter-spacing: 3px;
            margin-top: rem(20);
            margin-bottom: rem(40);
        }
    }
    @include media(700px){
        .col-6{
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: rem(40);
            &:last-child{
                margin-bottom: 0;
            }
        }
        .newsInner__imgBlock{
            img{
                min-height: auto;
                max-height: 300px;
            }
        }
    }
}

.newsOther{
   .title-block{
    justify-content: start;
   }
}

.base{
    .base__content{
        .title{
            font-size: rem(22);
            line-height: 33px;
            letter-spacing: 3px;
            display: block;
            margin-bottom: rem(20);
            margin-top: rem(40);
            text-transform: uppercase;
        }
    }
    .base__imgBlock{
        display: grid;
        grid-template-columns: 60% 40%;
        grid-template-rows: 300px 300px 300px;
        //grid-column-gap: 20px;
        grid-row-gap: rem(20);
        img{
            width: 100%;
            height: 100%;
            max-width: 100%;
            object-fit: cover;
        }
        .second{
            margin-right: rem(20);
        }
        .first{
            grid-column-start: 1;
            grid-column-end: 3;

        }
        .fourth{
            grid-column-start: 1;
            grid-column-end: 3;
        }
        .item a{
            height: 100%;
            width: 100%;
        }
        .item, .item a{
            @include flex-center();
            cursor: pointer;
            &:hover{
                .loop{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .loop{
            left: auto;
            top: auto;
            transform: none;
            opacity: 0;
            visibility: hidden;
            .icon{
                color: $c-main-text-light;
            }
        }
        .loop:hover{
            border: none;
            .icon{
                color: $c-main-text;
            }

        }

    }
    @include media(700px){
        .col-5, .col-7{
            flex: 0 0 100%;
            max-width: 100%;
        }
        .col-5{
            margin-bottom: rem(40);
        }
        .base__content{
            .title{
                font-size: rem(18);
                line-height: 27px;
                letter-spacing: 1px;
            }
        }
    }
    @include media(400px){
        .base__imgBlock{
            grid-template-columns: 50% 50%;
            grid-template-rows: 200px 200px 200px;
        }
    }
}

.baseGallery{
    .partners__item{
        padding-bottom: 0;
    }
    .swiper-button-next, .swiper-button-prev{
       top: 60%;
    }
    @include media(1550px){
        .swiper-button-next, .swiper-button-prev{
            top: auto;
            bottom: -100px;
        }
    }
    .partners__content:hover{
        .loop{
            opacity: 1;
            visibility: visible;
        }
    }
    .loop{
        position: absolute;
        left: 40%;
        top: 40%;
        transform: none;
        opacity: 0;
        visibility: hidden;
        .icon{
            color: $c-main-text-light;
        }
    }
    .loop:hover{
        border: none;
        .icon{
            color: $c-main-text;
        }

    }
}

.vacancy{
    .vacancy__imgBlock{
        img{
            width: 360px;
            height: 300px;
            min-width: 360px;
            object-fit: cover;
            object-position: center;
            display: flex;
        }
    }
    .vacancy__content{
        display: flex;
        margin-bottom: rem(80);
        &:nth-child(2n){
            flex-direction: row-reverse;
            .vacancy__info{
                left: auto;
                right: -60px;
            }
        }
    }
    .vacancy__info{
        padding: rem(40);
        background: $c-main-text;
        min-width: calc(77% - 60px);
        position: relative;
        left: -60px;
        top: 40px;
        .title{
            font-size: rem(22);
            line-height: 33px;
            letter-spacing: 3px;
            text-transform: uppercase;
            display: block;
            margin-bottom: rem(20);
            color: $c-main-bg;
        }
        .item{
            margin-bottom: rem(8);
            color: $c-main-bg;
            span{
                color: $c-main-text-light;
            }
            &:last-child{
                margin-bottom: rem(20);
            }
        }
        .accordion{
            max-width: 760px;
            .accordion__item{
                margin-bottom: rem(8);
            }
            .accordion__title{
                color: $c-main-bg;
                span{
                    font-family: $f-heading;
                    font-weight: 700;
                    font-size: rem(18);
                    line-height: 27px;
                    transition: $transition-primary;
                }
                .icon{
                    color: $c-main-bg;
                    transition: $transition-primary;
                }
                &:hover{
                    span{
                        color: $c-primary;
                    }
                    .icon{
                        color: $c-primary;
                    }
                }
            }
            .accordion__text{
                *{
                    color: $c-main-bg;
                }
                p{
                    margin-bottom: rem(20);
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    @include media(1280px){
        .vacancy__content{

            &:nth-child(2n){

                .vacancy__info{
                    left: auto;
                    right: -70px;
                }
            }
        }
        .vacancy__info{
            min-width: calc(70% - 60px);
            left: -70px;
        }
    }
    @include media(750px){
        .vacancy__content{
            flex-direction: column;
            margin-bottom: rem(40);
            &:nth-child(2n){
                flex-direction: column;
                .vacancy__info{
                    right: auto;
                }
            }
        }

        .vacancy__imgBlock{
            img{
                width: 100%;
                height: auto;
                min-width: auto;
            }
        }
        .vacancy__info{
            min-width: 100%;
            left: auto;
            right: auto;
            top: auto;
            .title{
                font-size: rem(18);
                line-height: 27px;
            }
        }
    }
}

.deliveryPage{
    .delivery__info{
        position: relative;
        padding-top: 70px;
        img{
            position: absolute;
            left: 50%;
            top: -50%;
            transform: translate(-50%, 44%);
            width: 340px;
            height: 415px;
            z-index: -1;
            border: 1px solid $c-main-text-light;
        }
        .delivery__item{
            padding: rem(20);
            background: $c-main-text;
            @include flex-center();
            margin-bottom: rem(20);
            &:last-child{
                margin-bottom: 0;
            }
            .value{
                color: $c-primary;
                font-family: $f-heading;
                font-weight: 700;
                font-size: rem(36);
                line-height: 45px;
                letter-spacing: 4px;
                display: block;
                margin-right: rem(8);
            }
            .text{
                font-family: $f-heading;
                font-weight: 700;
                color: $c-main-bg;
                font-size: rem(18);
                line-height: 27px;
            }
        }
    }
    @include media(700px){
        padding-bottom: rem(80);
        .col-7, .col-5{
            flex: 0 0 100%;
            max-width: 100%;
        }
        .col-7{
            margin-bottom: rem(40);
        }
        .delivery__info{
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
            .delivery__item{
                width: 100%;
                max-width: 600px;
            }
        }
    }
    @include media(580px){
        padding-bottom: 120px;
    }
}

.cars{
    .title-block{
        flex-direction: column;
        .title{
            margin-bottom: rem(40);
        }
    }
    .deliverySlider{
        .swiper-slide{
            padding-bottom: 0 !important;
        }
        img{
            min-height: 350px !important;
            max-height: 350px !important;
        }
        .partners__content:hover{
            .loop{
                opacity: 1;
                visibility: visible;
            }
        }
        .loop{
            position: absolute;
            left: 45%;
            top: 40%;
            transform: none;
            opacity: 0;
            visibility: hidden;
            .icon{
                color: $c-main-text-light;
            }
        }
        .loop:hover{
            border: none;
            .icon{
                color: $c-main-text;
            }

        }
    }
    .partners__content{
        display: flex;
        .first{
            flex: 0 0 65%;
            max-width: 65%;
        }
        .last{
            flex: 0 0 35%;
            max-width: 35%;
            margin-left: rem(20);
        }
    }
    @include media(580px){
        .swiper-button-next, .swiper-button-prev{
            bottom: -100px;
        }
        .partners__content{
            .first{
                flex: 0 0 100%;
                max-width: 100%;
            }
            .last{
                display: none;
            }
        }
    }
    @include media(450px){
        .partners__content{
            img{
                min-height: 350px !important;
            }
        }
    }

}
body.compensate-for-scrollbar {
    overflow: hidden;
}

.fancybox-active {
    height: auto;
}

.fancybox-is-hidden {
    left: -9999px;
    margin: 0;
    position: absolute !important;
    top: -9999px;
    visibility: hidden;
}

.fancybox-container {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
    height: 100%;
    left: 0;
    position: fixed;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    width: 100%;
    z-index: 99992;
}

.fancybox-container * {
    box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fancybox-outer {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}

.fancybox-bg {
    background: #1e1e1e;
    opacity: 0;
    transition-duration: inherit;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
    opacity: .87;
    transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
    direction: ltr;
    opacity: 0;
    position: absolute;
    transition: opacity .25s, visibility 0s linear .25s;
    visibility: hidden;
    z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
    opacity: 1;
    transition: opacity .25s, visibility 0s;
    visibility: visible;
}

.fancybox-infobar {
    color: #ccc;
    font-size: 13px;
    -webkit-font-smoothing: subpixel-antialiased;
    height: 44px;
    left: 0;
    line-height: 44px;
    min-width: 44px;
    mix-blend-mode: difference;
    padding: 0 10px;
    pointer-events: none;
    text-align: center;
    top: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fancybox-toolbar {
    right: 0;
    top: 0;
}

.fancybox-stage {
    direction: ltr;
    overflow: visible;
    -webkit-transform: translate3d(0, 0, 0);
    z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
    overflow: hidden;
}

.fancybox-slide {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: none;
    height: 100%;
    left: 0;
    outline: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 44px;
    position: absolute;
    text-align: center;
    top: 0;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    white-space: normal;
    width: 100%;
    z-index: 99994;
}

.fancybox-slide::before {
    content: '';
    display: inline-block;
    height: 100%;
    margin-right: -.25em;
    vertical-align: middle;
    width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
    display: block;
}

.fancybox-slide--next {
    z-index: 99995;
}

.fancybox-slide--image {
    overflow: visible;
    padding: 44px 0;
}

.fancybox-slide--image::before {
    display: none;
}

.fancybox-slide--html {
    padding: 6px 6px 0 6px;
}

.fancybox-slide--iframe {
    padding: 44px 44px 0;
}

.fancybox-content {
    background: #fff;
    display: inline-block;
    margin: 0 0 6px 0;
    max-width: 100%;
    overflow: auto;
    padding: 0;
    padding: 24px;
    position: relative;
    text-align: left;
    vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
    animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: transparent;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0;
    margin: 0;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}

.fancybox-can-drag .fancybox-content {
    cursor: -webkit-grab;
    cursor: grab;
}

.fancybox-is-dragging .fancybox-content {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.fancybox-container [data-selectable='true'] {
    cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
    background: transparent;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
}

.fancybox-spaceball {
    z-index: 1;
}

.fancybox-slide--html .fancybox-content {
    margin-bottom: 6px;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
    height: 100%;
    margin: 0;
    overflow: visible;
    padding: 0;
    width: 100%;
}

.fancybox-slide--video .fancybox-content {
    background: #000;
}

.fancybox-slide--map .fancybox-content {
    background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
    background: #fff;
    height: calc(100% - 44px);
    margin-bottom: 44px;
}

.fancybox-video,
.fancybox-iframe {
    background: transparent;
    border: 0;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

.fancybox-iframe {
    vertical-align: top;
}

.fancybox-error {
    background: #fff;
    cursor: default;
    max-width: 400px;
    padding: 40px;
    width: 100%;
}

.fancybox-error p {
    color: #444;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

/* Buttons */
.fancybox-button {
    background: rgba(30, 30, 30, 0.6);
    border: 0;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    height: 44px;
    margin: 0;
    outline: none;
    padding: 10px;
    transition: color .2s;
    vertical-align: top;
    width: 44px;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
    color: #ccc;
}

.fancybox-button:focus,
.fancybox-button:hover {
    color: #fff;
}

.fancybox-button.disabled,
.fancybox-button.disabled:hover,
.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
    color: #888;
    cursor: default;
}

.fancybox-button svg {
    display: block;
    overflow: visible;
    position: relative;
    shape-rendering: geometricPrecision;
}

.fancybox-button svg path {
    fill: transparent;
    stroke: currentColor;
    stroke-linejoin: round;
    stroke-width: 3;
}

.fancybox-button--play svg path:nth-child(2) {
    display: none;
}

.fancybox-button--pause svg path:nth-child(1) {
    display: none;
}

.fancybox-button--play svg path,
.fancybox-button--share svg path,
.fancybox-button--thumbs svg path {
    fill: currentColor;
}

.fancybox-button--share svg path {
    stroke-width: 1;
}

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
    height: 38px;
    opacity: 0;
    padding: 6px;
    position: absolute;
    top: 50%;
    width: 38px;
}

.fancybox-show-nav .fancybox-navigation .fancybox-button {
    transition: opacity .25s, visibility 0s, color .25s;
}

.fancybox-navigation .fancybox-button::after {
    content: '';
    left: -25px;
    padding: 50px;
    position: absolute;
    top: -25px;
}

.fancybox-navigation .fancybox-button--arrow_left {
    left: 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
    right: 6px;
}

/* Close button on the top right corner of html content */
.fancybox-close-small {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #555;
    cursor: pointer;
    height: 44px;
    margin: 0;
    padding: 6px;
    position: absolute;
    right: 0;
    top: 0;
    width: 44px;
    z-index: 10;
}

.fancybox-close-small svg {
    fill: transparent;
    opacity: .8;
    stroke: currentColor;
    stroke-width: 1.5;
    transition: stroke .1s;
}

.fancybox-close-small:focus {
    outline: none;
}

.fancybox-close-small:hover svg {
    opacity: 1;
}

.fancybox-slide--image .fancybox-close-small,
.fancybox-slide--video .fancybox-close-small,
.fancybox-slide--iframe .fancybox-close-small {
    color: #ccc;
    padding: 5px;
    right: -12px;
    top: -44px;
}

.fancybox-slide--image .fancybox-close-small:hover svg,
.fancybox-slide--video .fancybox-close-small:hover svg,
.fancybox-slide--iframe .fancybox-close-small:hover svg {
    background: transparent;
    color: #fff;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
    display: none;
}

/* Caption */
.fancybox-caption {
    bottom: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    left: 0;
    line-height: 1.5;
    padding: 25px 44px 25px 44px;
    right: 0;
}

.fancybox-caption::before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
    background-repeat: repeat-x;
    background-size: contain;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -25px;
    z-index: -1;
}

.fancybox-caption::after {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    content: '';
    display: block;
    left: 44px;
    position: absolute;
    right: 44px;
    top: 0;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
    color: #ccc;
    text-decoration: none;
}

.fancybox-caption a:hover {
    color: #fff;
    text-decoration: underline;
}

/* Loading indicator */
.fancybox-loading {
    -webkit-animation: fancybox-rotate .8s infinite linear;
    animation: fancybox-rotate .8s infinite linear;
    background: transparent;
    border: 6px solid rgba(100, 100, 100, 0.5);
    border-radius: 100%;
    border-top-color: #fff;
    height: 60px;
    left: 50%;
    margin: -30px 0 0 -30px;
    opacity: .6;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 60px;
    z-index: 99999;
}

@-webkit-keyframes fancybox-rotate {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes fancybox-rotate {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

/* Transition effects */
.fancybox-animated {
    transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
    opacity: 0;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
    opacity: 1;
}

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
    -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
    transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
    -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
    transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
}

/* Share */
.fancybox-share {
    background: #f4f4f4;
    border-radius: 3px;
    max-width: 90%;
    padding: 30px;
    text-align: center;
}

.fancybox-share h1 {
    color: #222;
    font-size: 35px;
    font-weight: 700;
    margin: 0 0 20px 0;
}

.fancybox-share p {
    margin: 0;
    padding: 0;
}

.fancybox-share__button {
    border: 0;
    border-radius: 3px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    margin: 0 5px 10px 5px;
    min-width: 130px;
    padding: 0 15px;
    text-decoration: none;
    transition: all .2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
    color: #fff;
}

.fancybox-share__button:hover {
    text-decoration: none;
}

.fancybox-share__button--fb {
    background: #3b5998;
}

.fancybox-share__button--fb:hover {
    background: #344e86;
}

.fancybox-share__button--pt {
    background: #bd081d;
}

.fancybox-share__button--pt:hover {
    background: #aa0719;
}

.fancybox-share__button--tw {
    background: #1da1f2;
}

.fancybox-share__button--tw:hover {
    background: #0d95e8;
}

.fancybox-share__button svg {
    height: 25px;
    margin-right: 7px;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 25px;
}

.fancybox-share__button svg path {
    fill: #fff;
}

.fancybox-share__input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0;
    color: #5d5b5b;
    font-size: 14px;
    margin: 10px 0 0 0;
    outline: none;
    padding: 10px 15px;
    width: 100%;
}

/* Thumbs */
.fancybox-thumbs {
    background: #fff;
    bottom: 0;
    display: none;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding: 2px 2px 4px 2px;
    position: absolute;
    right: 0;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    width: 212px;
    z-index: 99995;
}

.fancybox-thumbs-x {
    overflow-x: auto;
    overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
    display: block;
}

.fancybox-show-thumbs .fancybox-inner {
    right: 212px;
}

.fancybox-thumbs>ul {
    font-size: 0;
    height: 100%;
    list-style: none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

.fancybox-thumbs-x>ul {
    overflow: hidden;
}

.fancybox-thumbs-y>ul::-webkit-scrollbar {
    width: 7px;
}

.fancybox-thumbs-y>ul::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y>ul::-webkit-scrollbar-thumb {
    background: #2a2a2a;
    border-radius: 10px;
}

.fancybox-thumbs>ul>li {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    cursor: pointer;
    float: left;
    height: 75px;
    margin: 2px;
    max-height: calc(100% - 8px);
    max-width: calc(50% - 4px);
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    width: 100px;
}

.fancybox-thumbs-loading {
    background: rgba(0, 0, 0, 0.1);
}

.fancybox-thumbs>ul>li {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.fancybox-thumbs>ul>li:before {
    border: 4px solid #4ea7f9;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 99991;
}

.fancybox-thumbs .fancybox-thumbs-active:before {
    opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
    .fancybox-thumbs {
        width: 110px;
    }

    .fancybox-show-thumbs .fancybox-inner {
        right: 110px;
    }

    .fancybox-thumbs>ul>li {
        max-width: calc(100% - 10px);
    }
}
#svg{
    position: relative;

}

.map{
    &__container{
        position: relative;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        padding-top: 18px;
        padding-bottom: 18px;
    }
    &-desc{
        height: 150px;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden; /* Обрезка содержимого */
        clip-path: inset(100% 0 0 0); /* Полностью скрыт снизу */
        transition: clip-path $transition-primary ease; /* Плавный переход */
        border-left: 2px solid $additional-color;

        &.map-desc-bottom{
            top: 167px;
            left: -350px;
            overflow: hidden; /* Обрезка содержимого */
            clip-path: inset(0 0 100% 0); /* Полностью скрыт снизу */
            transition: clip-path .5s ease; /* Плавный переход */
            border-left: none;
            border-right: 2px solid $additional-color;
            display: flex;
            align-items: end;
        }
        &-bg{
            background-color: $additional-color;
            padding: 12px;
            width: 350px;
        }
        & p{
            color: $c-main-bg;
            margin: 0;
        }
        &-h1{
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
        }
    }
    &-desc.active{

        clip-path: inset(0 0 0 0);
    }
}
.point{
    transition:  $transition-primary ;
}
.point:hover{
    cursor: pointer;
    transition:  $transition-primary ;
}
.politic{
    & h1{
        text-align: center;
        font-size: 28px;
    }
    & .product-h{
        margin-top: 40px;
        margin-bottom: 20px;
    }
    & li{
        padding-left: 24px;
        position: relative;
        margin-bottom: 0.5rem;
    }
    & li::after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 8px;
        height: 8px;
        background: #FBCE11;
        transform: translate(0, 8px) rotate(45deg);
    }
    & a{
        color: $c-main-text;
        text-decoration: underline;
        transition: $transition-primary;
    }
    & a:hover{
        color: $c-primary;
    }
    @include media(580px){
        & h1{
            font-size: 18px;
        }
    }
}
.btn-up-position{
    position: fixed;
    right: 0;
    left: 0;
    bottom: 65px;
    margin-left: 250px;
    z-index: 99991;
    @include media(1000px){
        margin-left: 0;
        bottom: 35px;
    }
}
.btn-up{
    position: absolute;
    right: -90%;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    background: #FAF9F5;
    border-radius: 50%;
    border: 1px solid $c-main-text-light;
    z-index: 3333;
    transition: $transition-primary;
    @include media(1000px){
        right: -80%;
    }
    @include media(500px){
        right: -70%;
    }
    .icon{
        transform: rotate(90deg);
        color: $c-main-text-light;
        transition: $transition-primary;
    }

    @include media(960px){

    }
    &.btn-up_hide{
        right: 0;
        margin-right: auto;
        margin-left: auto;
        transition: $transition-primary;
        .icon{
            transform: rotate(-90deg);
            transition: $transition-primary;
        }
    }
    &:hover{
        background: $c-primary;
        color: $c-main-text;
        border: none;
        cursor: pointer;
    }
}
.demoTabs__content{
    display: none;
    &.active{
        display: block;
    }
    &-img{
        display: none;
        &.active{
            display: block;
        }
    }
}
.careers-button{
    display: block;
    @include media(1000px){
        display: none !important;
    }
}
.careers-pagination{
     display: none;
     bottom: 0!important;
    @include media(1550px){
        padding-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .swiper-pagination-bullet{
        width: 8px;
        height: 8px;
        border: 0;
        margin: 0 8px;
        opacity: 1;
        border-radius: 0;
        background: #80807D99;

        &-active{
            background: $c-primary;
            width: 16px;
            height: 16px;
        }
    }
}
.about__us{
    .loop{
        top: 50%;
    }
    a{
        width: 100%;
        height: 100%;
    }
    .swiper-slide{
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        .partners__content{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            a{
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    @include media(1050px){
        padding-bottom: 0!important;
        .swiper-button-next, .swiper-button-prev{
            bottom: 10px;
            border: 1px solid $c-main-text-light;
        }
        .swiper-button-prev{
            right: 52%;
            left: auto;
            transform: translate(0, -50%) rotate(180deg);
        }
        .swiper-button-next{
            left: 52%;
            right: auto;
            transform: translate(0, -50%);
        }
    }
}
.mb-0{
    margin-bottom: 0;
}
.history__gallery{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100px;
    img{
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }
}
.slider-dark{
    .swiper-slide-visible{
        filter: grayscale(100%);
    }
    .swiper-slide-visible.swiper-slide-thumb-active{
        filter: grayscale(0%);
    }
}
.pt-40{
    padding-top: 40px;
}
.mt-60{
    margin-top: 60px;
}
.mb-60{
    margin-botom: 60px;
}
.pt-100{
    padding-top: 100px !important;
    @include media(1000px){
        padding-top: 80px !important;
    }
    @include media(500px){
        padding-top: 60px !important;
    }

}
body.fancybox-active {
    overflow: hidden;
    scrollbar-width: none;
}
.map__frame-yandex{
    overflow: hidden;
    width: 100%;
    height: 650px;
    @include media(1000px){
        height: 500px;
        display: flex;
        align-items: center;
    }
    @include media(500px){
        height: 400px;
    }
}
.w-100{
    max-width: 100% !important;
    width: 100% !important;
}
.projects__none > :nth-child(3){
    @include media(500px){
        display: none;
    }

}
.projects__none > :nth-child(4){
    @include media(1000px){
        display: none;
    }

}
.main__screen-2{
    display: flex;
    justify-content: center;
    width: 100%;
    @include media(1000px){
        & > :nth-child(n){
            max-width: 50% !important;
            flex: 0 0 50% !important;
        }
        & > :nth-child(3){
            display: none !important;


        }

    }
    @include media(500px){
        & > :nth-child(2){
            display: none !important;

        }
        & > :nth-child(n){
            max-width: 100% !important;
            flex: 0 0 100% !important;
        }
        a{
            display: flex;
            justify-content: center;
            .thirdScreen__content-img{
                width: 100%;
            }
        }

    }


}
.mt-sm-20{
    @include media($sm){
        margin-top: 20px !important;

    }
}
.sandpits-map__сities-markers-label{
    fill: #252422;
    font-size: 11px;
}
.sandpits-map__сities-markers-white{
    fill: #fff;
    font-size: 11px;
}
.quarries-map__region, .quarries-map__region-gradient{
    transition: all $transition-primary ease;
    cursor: pointer;
}
.quarries-map__region-gradient:hover{
    fill: url(#paint0_linear_74_83692_1);
}
.quarries-map__region:hover{
    fill: #eab817 !important;
}
@media screen and (max-width: 1170px){
    .d-none-1170{
        display: none;
    }
}
@media screen and (min-width: 1171px){
    .d-none-min-1170{
        display: none;
    }
}
.vacancy__none{
    font-size: 1.375rem;
    line-height: 33px;
    letter-spacing: 3px;
    font-family: "Actay", arial, sans-serif;
    font-weight: 400;
    margin-top: 1.25rem;
    text-align: center;
}