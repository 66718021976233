$font-size-text: rem(18);
$font-size-title: rem(28);
$font-weight-title: 700;
$margin-bottom-title: rem(40);
$letter-spaceing-title: 4px;
p {
  font-size: $font-size-text;
  margin-bottom: 1rem;
  margin-top: 0;
  color: $c-main-text;
  letter-spacing: -0.16px;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
  font-size: $font-size-text;
  line-height: 1.6;

}

a {
  color: $c-main-bg;
  transition: color 0.3s ease;
  display: inline-block;
  text-decoration: none;
}

a:hover {
  color: $c-primary;
  text-decoration: none;
}
a.btn:hover{
  color: $c-main-text;
}


h2, .h2 {
  font-size: $font-size-title;
  line-height: 1.2;
  font-weight: $font-weight-title;
  margin: 0 0 $margin-bottom-title 0;
  letter-spacing: $letter-spaceing-title;
  @include phone() {
    font-size: rem(30);
  }
}

ol {
  padding-left: rem(20);
  margin-bottom: rem(30);
}

ol li {
  margin-bottom: rem(10);
}
