$padding: rem(60);
$padding-phone: rem(40);
.section-spacer {
  // базовый блок секции
  
  padding: $padding 0;
  @include phone() {
    padding: $padding-phone 0;
  }

}

