@mixin font($alias, $name, $weight) {
  @font-face {
    font-family: $alias;
    src: url("../fonts/" + $name + "/" + $name + ".woff2") format("woff2"),
      url("../fonts/" + $name + "/" + $name + ".woff") format("woff"),
      url("../fonts/" + $name + "/" + $name + ".ttf") format("truetype"),
      url("../fonts/" + $name + "/" + $name + ".svg") format("svg"),
      url("../fonts/" + $name + "/" + $name + ".eot"),
      url("../fonts/" + $name + "/" + $name + ".eot?#iefix")
        format("embedded-opentype");
    font-weight: $weight;
    font-display: swap;
  }
}

@include font("Actay", "Actay-Regular", 400);
@include font("ActayWide", "ActayWide-Bold", 700);


