@import "functions/vars";

@media only screen and (min-width: 1920px) {
  .container {
    max-width: rem(1200);
  }
}

@media only screen and (min-width: 2000px) {
  html {
    font-size: 110%;
  }
}

@media only screen and (max-width: 992px) {
  html {
    font-size: 90%;
  }
}

@media only screen and (max-width: 580px) {
  html {
    font-size: 80%;
  }
}
@media screen and (max-height: 900px) {
  .nav{
    padding: 1.3rem;
  }
  .nav .nav__menu {
    margin-top: 0.5rem;
  }
  .nav .nav__menu li {
    line-height: 16px;
  }
  .nav__social {
    margin: 0 0 1rem 0;
  }
  .nav .nav__contact {
    margin-bottom: 1rem;
  }
  .nav .nav__contact .nav__adress{
    margin-bottom: .5rem;
  }
}

